import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHead from '../images/head.png'
import imgEyeBackground from '../images/eyes-background.png'
import imgEye from '../images/eye.png'
import '../style/face.scss'

const
  eyeLeftMin = 120,
  eyeLeftMax = 170,
  eyeRightMin = 350,
  eyeRightMax = 410,
  eyeRightTopMax = 0,
  scaleBy = 0.25,
  eyeLeftMinScaled = scaleBy * eyeLeftMin,
  eyeLeftMaxScaled = scaleBy * eyeLeftMax,
  eyeRightMinScaled = scaleBy * eyeRightMin,
  eyeRightMaxScaled = scaleBy * eyeRightMax;


function hasClass(el, className) {
  return el.classList ? el.classList.contains(className) : new RegExp('\\b'+ className+'\\b').test(el.className);
}

function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
}

function css(el, styles) {
  for (var property in styles)
    el.style[property] = styles[property];
}

function animate(el, direction, toPosition, duration, next) {
  const s = el.style
  let multiplier = 1;
  const val = s[direction] ? s[direction] : 0
  const startedAt = parseFloat(val);
  const diff = Math.abs(toPosition - startedAt)
  const step = (diff / duration) * 25
  if (toPosition < startedAt) {
    multiplier = -1
  }
  (function move() {
    let moveTo = parseFloat(s[direction] || 0)
    //console.log('MOVE', startedAt, moveTo, toPosition, s[direction], step, (multiplier * step))
    moveTo += (multiplier * step)
    s[direction] = moveTo + 'px'
    if ((multiplier === -1 && moveTo > toPosition) || (multiplier === 1 && moveTo < toPosition)) {
      setTimeout(move, 25);
    } else {
      next && next()
    }
  })();
}


const handleCursor = (event) => {
  var eyeLeft = document.getElementById('eye-left'),
    eyeRight = document.getElementById('eye-right')
  const x = event.pageX,
    windowMax = 1000
  let eyeLeftPos, eyeRightPos;




    if (!hasClass(eyeLeft,'animating')) {
      eyeLeftPos = eyeLeftMinScaled + ((x / windowMax) * (eyeLeftMaxScaled - eyeLeftMinScaled));
      css(eyeLeft, {left: eyeLeftPos + 'px'});
    }
    if (!hasClass(eyeRight,'animating')) {
      eyeRightPos = eyeRightMinScaled + ((x / windowMax) * (eyeRightMaxScaled - eyeRightMinScaled));
      css(eyeRight,{left: eyeRightPos + 'px'});
    }

}

class IndexPage extends React.Component {

  componentDidMount() {
    // When the component is mounted, add your DOM listener.
    if (typeof document !== 'undefined') document.addEventListener("mousemove", handleCursor);
  }



  render() {
    const posts = []

    return (
      <Layout>
        <SEO title="Home"/>
        <div className="home">

          <section id="am-image-container" onClick={() => {
            const eyeLeft = document.getElementById('eye-left'), eyeRight = document.getElementById('eye-right')
            animate(eyeLeft, 'left', eyeLeftMaxScaled, 500,
              () => {
                setTimeout(() => {
                  animate(eyeLeft, 'left', eyeLeftMinScaled, 500,
                    () => {
                      setTimeout(() => {
                        animate(eyeLeft, 'left', eyeLeftMaxScaled, 500,
                          () => {
                            setTimeout(() => {
                              animate(eyeLeft, 'left', eyeLeftMinScaled + ((eyeLeftMaxScaled - eyeLeftMinScaled) / 2), 500)
                            }, 500)
                          })
                      }, 500)
                    }, 500)
                }, 2000)
              }
            )

            animate(eyeRight, 'left', eyeRightMinScaled, 500,
              () => {
                setTimeout(() => {
                  animate(eyeRight, 'left', eyeRightMaxScaled, 500,
                    () => {
                      setTimeout(() => {
                        animate(eyeRight, 'left', eyeRightMinScaled, 500,
                          () => {
                            setTimeout(() => {
                              animate(eyeRight, 'left', eyeRightMinScaled + ((eyeRightMaxScaled - eyeRightMinScaled) / 2), 500)
                            }, 2000)
                          })
                      }, 500)
                    }, 500)
                }, 500)
              }
            )
          }}>
            <div>
              <img className="head" src={imgHead}/>
              <img className="eyes-background" src={imgEyeBackground}/>
              <img id="eye-left" className="eye left look-center" src={imgEye}/>
              <img id="eye-right" className="eye right look-center" src={imgEye}/>
            </div>
          </section>

          <article>
            <h2>Ye Gads</h2>
            <p>In this mighty expanse that we know as the Web, you have landed at this site, the site of <abbr
              title="James (for the benefit of my Ma)">Jim</abbr> Levett, software developer and all round nice guy who,
              according to some, bears a passing resemblance to Action Man.</p>

            <h2>About <abbr title="Jim Levett" className="jimlevett">Me</abbr></h2>
            <p>From my first <span className="code">10 PRINT "Hello World!"</span> upon my <a
              href="https://en.wikipedia.org/wiki/ZX_Spectrum#ZX_Spectrum_.2B2"
              className="wikipedia">Spectrum</a> in <abbr
              title="Gazza joins Spurs">1988</abbr>, to version 1.0 of my personal website as a teenager in <abbr
              title="Blur > Oasis">1996</abbr>, followed by the completion of my Computer Science degree at <a
              href="https://twitter.com/kingscollegelon" className="twitter-link">King's College London</a> and then
              through
              to my current <strong>Senior Developer</strong> role at <a href="http://www.madebykite.com">Kite</a> it
              would
              be fair to say that I have held up a sustained attack in the fight to tame our high-tech friends.</p>
            <p>Throughout that time, I've grown and wisened, I've loved, <a
              href="http://www.stolenbristolbikes.com/2013/06/jim-whyte-malvern.html">lost</a> and <a
              href="http://www.genesisbikes.co.uk/blog/15/07/13/2014-croix-tweaked-refined">loved again</a> - and I'm
              hoping
              this time she won't be stolen from me. I've travelled around the world and come back again, having enjoyed
              the
              best ribs in Memphis, the best steak in Brooklyn, castles with views of castles throughout Albania, wine
              with
              kangaroos in Margaret River, cricket with the locals in St Vincent, Karaoke with strangers that soon
              became
              friends in Tokyo and countless other escapades and hopefully many more to come. </p>
            <p>In <abbr title="London Olympics and all that">2012</abbr> I moved from the <abbr title="London">big
              smoke</abbr> to the hilly city of Bristol in what was billed as a temporary move, 3 years later and you'll
              still catch me working away at the <a href="http://mildbun.ch">Mild Bunch HQ</a> or at least in one of the
              many great <span className="swap"><span className="swap-default">coffee</span><span
                className="swap-replace">booze</span></span> serving outlets around town.</p>
          </article>

          {(posts.length > 0) ?
            <>
              <h1 className="page-heading">Posts</h1>
              <ul className="post-list">
                {posts.map(_post => (
                  <li>
                    <span className="post-meta">{_post.date + 'date: "%b %-d, %Y"'}</span>
                    <h2><a className="post-link" href={_post.url + '| prepend: site.baseurl '}>{_post.title}</a></h2>
                  </li>
                ))}
              </ul>
              <p className="rss-subscribe">subscribe <a href={"/feed.xml" + '| prepend: site.baseurl '}>via RSS</a></p>
            </> : null
          }
        </div>
      </Layout>
    )
  }
}


export default IndexPage